import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import logo from '../../assets/logos/logo.png'

export const Container = styled.div`
  min-height: 100vh;
  width: 100vw;
  background: ${p => p.theme.color.bg};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Text = styled.h1`
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 1rem;
  align-self: flex-start;
`

export const StyledLink = styled(Link)`
  color: ${p => p.theme.color.text};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  text-decoration: none;
  margin-top: 1rem;
  align-self: flex-start;
`

export const Box = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  min-width: 280px;

  ${p => p.theme.layout.web} {
    height: fit-content;
    padding: 40px;
    padding-top: 0;
    min-width: 380px;
  }
  form {
    width: 100%;
  }
`

const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  ${p => p.theme.layout.web} {
    flex-direction: row;
  }
`

const LogoWeb = styled.img`
  display: none;
  ${p => p.theme.layout.web} {
    display: block;
    width: 60px;
    height: 60px;
  }
`
const LogoMob = styled.img`
  display: block;
  align-self: start;
  max-width: 60px;
  margin-bottom: 2rem;
  ${p => p.theme.layout.web} {
    display: none;
  }
`

export const BoxWithLogo = ({ children }) => (
  <LoginBox>
    <LogoWeb src={logo} />
    <Box>
      <LogoMob src={logo} alt="logo" />
      {children}
    </Box>
  </LoginBox>
)

BoxWithLogo.propTypes = {
  children: PropTypes.node.isRequired,
}
