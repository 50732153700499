import React from 'react'
import PropTypes from 'prop-types'
import {
  Page,
  Font,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'

import Logo from '../../assets/logos/logo.png'

import AkkRgWoff from '../../assets/fonts/AkkRg_Pro_1.woff'
import AkkBdWoff from '../../assets/fonts/AkkBd_Pro_1.woff'
import AkkItWoff from '../../assets/fonts/AkkIt_Pro_1.woff'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    color: '#575756',
    padding: '2rem',
  },
  header: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginBottom: 4,
    logo: {
      width: '60px',
      marginLeft: 'auto',
      marginBottom: 6,
    },
    title: {
      color: '#000',
      flexGrow: 1,
    },
    heading: {
      color: '#000',
      fontSize: 14,
      marginTop: '2rem',
    },
  },
  section: {
    width: '100%',
    marginTop: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    width: '100%',
    display: 'table',
    head: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: 10,
      padding: '0 0 5px',
      borderStyle: 'solid',
      borderColor: '#c5c5c5',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
    },
    row: {
      width: '100%',
      padding: '10px 0',
      fontSize: 9,
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderStyle: 'solid',
      borderColor: '#c5c5c5',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
    },
    tableCol1: {
      width: '12%',
      textAlign: 'center',
    },
    tableCol2: {
      width: '15%',
      textAlign: 'center',
    },
    tableCol3: {
      width: '25%',
    },
  },
})

Font.register({
  family: 'Akkurat',
  fonts: [
    { src: AkkRgWoff },
    { src: AkkBdWoff, fontWeight: 600 },
    { src: AkkItWoff, fontWeight: 700 },
  ],
})

// Create Document Component
const ConstChangeCostPDF = ({ data, project }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.header.title}>{project.title}</Text>
          <Image src={Logo} style={styles.header.logo} />
        </View>
        <View style={styles.header}>
          <Text style={styles.header.heading}>
            Construction Change Cost Status
          </Text>
        </View>
        <View style={styles.section}>
          <View style={styles.table}>
            <View style={styles.table.head}>
              <Text style={styles.table.tableCol1}></Text>
              <Text style={styles.table.tableCol3}>
                Budgets Costs by Cost Manager
              </Text>
              <Text style={styles.table.tableCol3}>
                Claim Submitted by Contractors
              </Text>
              <Text style={styles.table.tableCol3}>
                Cost Manager Assessments
              </Text>
            </View>
            <View>
              {data
                ? data.map(row => (
                    <React.Fragment key={row.name}>
                      <View style={styles.table.row}>
                        <Text style={styles.table.tableCol1}>{row.name}</Text>
                        <Text style={styles.table.tableCol3}>{row.budget}</Text>
                        <Text style={styles.table.tableCol3}>
                          {row.claimed}
                        </Text>
                        <Text style={styles.table.tableCol3}>
                          {row.assessed}
                        </Text>
                      </View>
                    </React.Fragment>
                  ))
                : null}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

ConstChangeCostPDF.defaultProps = {
  project: null,
}

ConstChangeCostPDF.propTypes = {
  data: PropTypes.array.isRequired,
  project: PropTypes.object,
}

export { ConstChangeCostPDF }
